export { matchers } from './client-matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57')];

export const server_loads = [];

export const dictionary = {
	"/": [9],
	"/ai": [10,[2]],
	"/ai/copilot": [11,[2]],
	"/calendar": [12],
	"/clients": [13],
	"/clients/list": [25],
	"/clients/[clientId]": [14,[3]],
	"/clients/[clientId]/bookings": [15,[3]],
	"/clients/[clientId]/healthplan": [16,[3]],
	"/clients/[clientId]/message": [17,[3,4]],
	"/clients/[clientId]/message/conversation": [19,[3,4]],
	"/clients/[clientId]/message/[conversationId]": [18,[3,4]],
	"/clients/[clientId]/notes": [20,[3]],
	"/clients/[clientId]/overview": [21,[3]],
	"/clients/[clientId]/profile": [22,[3]],
	"/clients/[clientId]/testresults": [23,[3]],
	"/clients/[clientId]/testresults/[reportId]": [24,[3]],
	"/companies": [26],
	"/companies/[companyId]/benefits": [27,[5]],
	"/companies/[companyId]/clients": [28,[5]],
	"/companies/[companyId]/roles": [29,[5]],
	"/companies/[companyId]/settings": [30,[5]],
	"/companies/[companyId]/tags": [31,[5]],
	"/companies/[companyId]/testphases": [32,[5]],
	"/companies/[companyId]/testphases/[testPhaseId]": [33,[5]],
	"/feature-toggle": [34],
	"/feature-toggle/new": [36],
	"/feature-toggle/[featureId]": [35],
	"/feedbacks": [37],
	"/kpis": [38],
	"/login": [39],
	"/message-logs": [40],
	"/messages": [41,[6]],
	"/messages/all": [42,[6]],
	"/messages/monitored": [43,[6]],
	"/okr": [44],
	"/profile": [45],
	"/register": [46],
	"/reports/merge": [51,[7]],
	"/reports/[reportId=integer]": [47,[8]],
	"/reports/[reportId=integer]/reportinformation": [48,[8]],
	"/reports/[reportId=integer]/survey": [49,[8]],
	"/reports/[reportType]": [50,[7]],
	"/reset-password": [52],
	"/settings": [53],
	"/stats": [54],
	"/styling-test": [55],
	"/unauthorized": [56],
	"/users": [57]
};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};